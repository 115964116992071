$(document).ready(() => {
  let $plPopNewsModal = $('#pl-pop-news-modal');

  if ($plPopNewsModal.length) {
    $plPopNewsModal.modal('show');
    $plPopNewsModal.on('submit', 'form', function(e) {
      e.preventDefault();
      const $form = $(e.currentTarget);
      $.ajax({
        type: 'POST',
        url: popNewsUrl,
        cache: false,
        data: $(this).serialize(),
        success: function (data) {
          $form.replaceWith(data);
        }
      });
    });
  }
});
